import Head from 'next/head'
import Link from 'next/link'
import * as fcl from '@onflow/fcl'

import {dimensionX} from 'common/flow'
import { gtag, install } from 'ga-gtag';
import {useAppContext} from 'context/AppContext'
import CookieConsent from 'react-cookie-consent'
import config from 'global/config'
import { useEffect, useState } from 'react'

import Script from 'next/script'
export default function Home() {
  const {user, hasCollection, checkCollection, setIsLoading} = useAppContext()

  useEffect(() => {
    if(user.addr) {
      install('G-R0TSZ42E0Z', { 'send_page_view': false });
      gtag('event', 'wallet_connected', { 'method': 'Google' });
      console.log(user.addr)
    }
  }, [user])
  const setupAccount = async () => {
    setIsLoading(true)
    try {
      await dimensionX.setupAccount()
      await checkCollection()
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const UnauthenticatedState = () => {
    const logIn = async () => {
      setIsLoading(true)
      await fcl.authenticate()
      setIsLoading(false)
    }
    return (
      <>
        <div className="mx-auto -mt-16 mb-64 content-center">
          <button
            onClick={logIn}
            className="w- font-graveside btn-orange text-shadow drop-shadow-lg"
          >
            <div className="animate-pulse">
              CONNECT WALLET TO LOGIN
            </div>
          </button>
        </div>
      </>
    )
  }

  const UninitializedState = () => (
    <>
      <div className="mx-auto -mt-16 mb-64 content-center">
        <button
          onClick={setupAccount}
          className="font-graveside btn-orange text-shadow drop-shadow-lg"
        >
          <div className="animate-pulse">
            INITIALIZE ACCOUNT
          </div>
        </button>
      </div>
    </>
  )

  const remainingTime = () => new Date('2022-09-07T19:00:00.000Z') - Date.now()
  const formatCountdown = (ms) => {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const hours = `${Math.floor((ms % DAY) / HOUR)}`.padStart(2, '0')
    const minutes = `${Math.floor((ms % HOUR) / MINUTE)}`.padStart(2, '0')
    const seconds = `${Math.floor((ms % MINUTE) / SECOND)}`.padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }

  const InitializedState = () => { 
    const [timeLeft, setTimeLeft] = useState(remainingTime())
    const [countdownText, setCountdownText] = useState('')
    useEffect(() => {
      timeLeft > 0 && setTimeout(() => setTimeLeft(remainingTime()), 500)
    
    }, [timeLeft, user])
    return <>
      <div className="mx-auto -mt-16 mb-64 content-center transition group-hover:scale-105 duration-700 ease-in-out">
        <Link href="/game">
          <a className="w-">
            <img
              className="w-64"
              href="/game" 
              src={`${config.WEBCDN_URL}/images/DMX_Landing_PlayNow_Button.png`}
              alt="Play Now!"
              width={266}
              height={149}
            />
          </a>
        </Link>
      </div>
    </>
  }
    const onConsent = () => [

    ]
  return (
    <>
    <Script
      src="/3rdparty/crypthulhu_tracking_min.js"
      strategy="beforeInteractive"
    />
     <Script
      src="https://www.googletagmanager.com/gtag/js?id=G-R0TSZ42E0Z"
      strategy="afterInteractive"
    />
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-R0TSZ42E0Z');
      `}
    </Script>
    <div className="flex flex-wrap flex-col md:flex-col items-center">
      <Head>
        <title>Dimension X - The Game</title>
      </Head>
      <CookieConsent onAccept={onConsent}>
        This website uses cookies to save your game.
      </CookieConsent>

      {/* Left Col */}
      <div className="group w-full xl:w-3/5 flex flex-col justify-center content-center lg:items-start">
        <img
          className="mx-auto w-full md:w-4/5 transform transition group-hover:scale-105 duration-700 ease-in-out"
          src={`${config.WEBCDN_URL}/images/DMX_Landing_Logo.png`}
        />
        {user?.loggedIn ? (
          hasCollection ? (
            <InitializedState />
          ) : (
            <UninitializedState />
          )
        ) : (
          <UnauthenticatedState />
        )}
      </div>
    </div>
    </>
  )
}
